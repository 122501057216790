<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addDealerPromotionsModal' hide-footer>
        <addDealerPromotions @closeAddDealerPromotions='toggleAddDealerPromotions()' @addDealerPromotionsSuccess='addDealerPromotionsSuccess()'></addDealerPromotions>
      </b-modal>
      <b-modal ref='editDealerPromotionsModal' hide-footer>
        <editDealerPromotions
          :editingDealerPromotions='currentDealerPromotions'
          @closeEditDealerPromotions='toggleEditDealerPromotions()'
          @editDealerPromotionsSuccess='editDealerPromotionsSuccess()'
        ></editDealerPromotions>
      </b-modal>
      <div class='row'>
        <h2>DealerPromotions</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddDealerPromotions()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addDealerPromotions from '../../components/addDealerPromotions';
import editDealerPromotions from '../../components/editDealerPromotions';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'DealerId',
                field: 'DealerId',
                sort: 'asc',
              },
              {
                label: 'VehicleId',
                field: 'VehicleId',
                sort: 'asc',
              },
              {
                label: 'Heading',
                field: 'Heading',
                sort: 'asc',
              },
              {
                label: 'Copy',
                field: 'Copy',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'ImageMeta',
                field: 'ImageMeta',
                sort: 'asc',
              },
              {
                label: 'DisplayOrder',
                field: 'DisplayOrder',
                sort: 'asc',
              },
              {
                label: 'Link',
                field: 'Link',
                sort: 'asc',
              },
              {
                label: 'LinkFriendly',
                field: 'LinkFriendly',
                sort: 'asc',
              },
              {
                label: 'CashPrice',
                field: 'CashPrice',
                sort: 'asc',
              },
              {
                label: 'TermsInMonths',
                field: 'TermsInMonths',
                sort: 'asc',
              },
              {
                label: 'Desposit',
                field: 'Desposit',
                sort: 'asc',
              },
              {
                label: 'Balloon',
                field: 'Balloon',
                sort: 'asc',
              },
              {
                label: 'RateToClient',
                field: 'RateToClient',
                sort: 'asc',
              },
              {
                label: 'TotalCostOfCredit',
                field: 'TotalCostOfCredit',
                sort: 'asc',
              },
              {
                label: 'StartingFrom',
                field: 'StartingFrom',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addDealerPromotionsOpen: false,
      editDealerPromotionsOpen: false,
      currentDealerPromotions: {}
    };
  },
  components: {
    addDealerPromotions,
    editDealerPromotions,
    Datatable,
  },
  created: function() {
    this.getDealerPromotions();
    this.addDealerPromotionsOpen = false;
    this.editDealerPromotionsOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getDealerPromotions() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getDealerPromotions', payload);
    },
    editItem(DealerPromotions) {
      this.toggleEditDealerPromotions();
      this.currentDealerPromotions = DealerPromotions;
    },
    toggleAddDealerPromotions() {
      if(this.addDealerPromotionsOpen)
      {
        this.$refs.addDealerPromotionsModal.hide()
      }
      else{
        this.$refs.addDealerPromotionsModal.show()
      }
      this.addDealerPromotionsOpen = !this.addDealerPromotionsOpen;
    },
    addDealerPromotionsSuccess() {
      this.toggleAddDealerPromotions();
      miniToastr['success']('DealerPromotions Added', 'Success', 1000, null);
      this.getDealerPromotions();
    },
    toggleEditDealerPromotions() {
      if(this.editDealerPromotionsOpen)
      {
        this.$refs.editDealerPromotionsModal.hide()
      }
      else{
        this.$refs.editDealerPromotionsModal.show()
      }
      this.editDealerPromotionsOpen = !this.editDealerPromotionsOpen;
    },
    editDealerPromotionsSuccess() {
      this.toggleEditDealerPromotions();
      miniToastr['success']('DealerPromotions Edited', 'Success', 1000, null);
      this.getDealerPromotions();
    }
  }
};
</script>
<style scoped>
.DealerPromotionsThumbnail {
  height: 50px;
}
</style>
