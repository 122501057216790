<template>
  <div class='container'>
  <h2>Add A DealerPromotions</h2>
   <button @click='save()' type='button' class='btn btn-success'>Save</button>
  <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
	<div class='row'>
            <div class=' col-md-4'><label class='' for='DealerId'>DealerId</label><input class=' form-control'   
                id='DealerId' name='DealerId' v-model='newDealerPromotions.DealerId' placeholder='DealerId' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='VehicleId'>VehicleId</label><input class=' form-control'   
                id='VehicleId' name='VehicleId' v-model='newDealerPromotions.VehicleId' placeholder='VehicleId' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Heading'>Heading</label><input class=' form-control'   
                id='Heading' name='Heading' v-model='newDealerPromotions.Heading' placeholder='Heading' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Copy'>Copy</label><input class=' form-control'   
                id='Copy' name='Copy' v-model='newDealerPromotions.Copy' placeholder='Copy' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
<div class='col-md-4'>
    <label class for='Image'>Image</label>
    <div v-if='!editingImage'>
      <img v-bind:src='getImageSource(newDealerPromotions)' class='previewImage'>
      <button @click='editImage()' type='button' class='btn btn-success'>Update Image</button>
    </div>
    <div v-if='editingImage'>
      <button @click='updateImage()' type='button' class='btn btn-success'>Update</button>
      <button @click='editImage()' type='button' class='btn btn-warning'>Cancel</button>
      <FileUploader @ImageDataChanged='updateImageData' @ImageURLDataChanged='updateImageUrl'/>
    </div>
  </div>
            <div class=' col-md-4'><label class='' for='ImageMeta'>ImageMeta</label><input class=' form-control'   
                id='ImageMeta' name='ImageMeta' v-model='newDealerPromotions.ImageMeta' placeholder='ImageMeta' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='DisplayOrder'>DisplayOrder</label><input class=' form-control'   
                id='DisplayOrder' name='DisplayOrder' v-model='newDealerPromotions.DisplayOrder' placeholder='DisplayOrder' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Link'>Link</label><input class=' form-control'   
                id='Link' name='Link' v-model='newDealerPromotions.Link' placeholder='Link' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='LinkFriendly'>LinkFriendly</label><input class=' form-control'   
                id='LinkFriendly' name='LinkFriendly' v-model='newDealerPromotions.LinkFriendly' placeholder='LinkFriendly' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='CashPrice'>CashPrice</label><input class=' form-control'   
                id='CashPrice' name='CashPrice' v-model='newDealerPromotions.CashPrice' placeholder='CashPrice' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='TermsInMonths'>TermsInMonths</label><input class=' form-control'   
                id='TermsInMonths' name='TermsInMonths' v-model='newDealerPromotions.TermsInMonths' placeholder='TermsInMonths' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Desposit'>Desposit</label><input class=' form-control'   
                id='Desposit' name='Desposit' v-model='newDealerPromotions.Desposit' placeholder='Desposit' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='Balloon'>Balloon</label><input class=' form-control'   
                id='Balloon' name='Balloon' v-model='newDealerPromotions.Balloon' placeholder='Balloon' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='RateToClient'>RateToClient</label><input class=' form-control'   
                id='RateToClient' name='RateToClient' v-model='newDealerPromotions.RateToClient' placeholder='RateToClient' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='TotalCostOfCredit'>TotalCostOfCredit</label><input class=' form-control'   
                id='TotalCostOfCredit' name='TotalCostOfCredit' v-model='newDealerPromotions.TotalCostOfCredit' placeholder='TotalCostOfCredit' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='StartingFrom'>StartingFrom</label><input class=' form-control'   
                id='StartingFrom' name='StartingFrom' v-model='newDealerPromotions.StartingFrom' placeholder='StartingFrom' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
            <div class=' col-md-4'><label class='' for='LastUpdated'>LastUpdated</label><input class=' form-control'   
                id='LastUpdated' name='LastUpdated' v-model='newDealerPromotions.LastUpdated' placeholder='LastUpdated' tabindex='0' type='text' value=''>
                <div
                    class=''><small></small></div>
            </div>
    <br/>
            </div>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
    </div>
    
</template>
<script>
import _ from 'lodash'; 
import FileUploader from '@/components/custom/FileUploader.vue'; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  name: 'addDealerPromotions',
  created: function() {
  },
  data() {
      return {
          newDealerPromotions: {},
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
        editingImage: false 
      }
  },
  components: { 
    FileUploader 
  }, 
  methods: {
    toggleView: function() {
      this.$emit('closeAddDealerPromotions')
    },
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
        if (typeof obj === 'undefined' || obj === null || obj === 'null') {
            return true;
        }
    return false;
    },
    save: function() {
    var payload = {
          data: this.newDealerPromotions,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('addDealerPromotionsSuccess')
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('addDealerPromotions', payload);
    }
  }
}
</script>
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
